.container {
    height: 60px;
    width: 60px;
    max-width: 60px;
    max-height: 60px;
    z-index:2;
    position: fixed;
    display: flex;
    // justify-content: center;
    align-items: center;
    bottom: 16px;
    left: 8px;
    opacity: 1;
    // padding: 0.5em 1em 1em 0.5em;
    // border: 2px solid black;
    border-radius: 25px;
    transition: opacity .1s ease-in-out, bottom .2s ease-in-out;
}
.container2 {
    opacity: 1;
    height: 242px;
    width: 330px;
    max-width: 330px;
    max-height: 242px;
    z-index:2;
    position: fixed;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    bottom: 8px;
    left: 16px;
    background-color: rgb(255, 255, 255);
    // padding: 0.5em 1em 1em 0.5em;
    // border: 2px solid black;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: hidden;
    transition: opacity .2s ease-in-out, max-height .3s ease-in-out, max-width .3s ease-in-out;
}
.top_bar {
    background-color: #7ac79b;
    height: 42px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.close_box {
    background-color: #7ac79b;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin-right: 15px;
    cursor: pointer;
    transition: background-color .1s ease-in-out;
}
.close_box:hover {
    background-color: #63a27e;
}
.close_box hr {
    width: 67%;
    border-top: 2px solid white;
    color: #fff;
    margin-bottom: 5px;
}
.span_text {
    font-family: Montserrat, sans-serif;
    padding: 12px;
    h3 {
        font-size: 24px;
        color: #262262;
        font-weight: bold;
        padding: 6px 0 15px;
    }
}
.mask {
    position: absolute;
    background-color: #fff;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: opacity .1s ease-in-out;
}
.icon {
    width: 60px;
    height: 60px;
    cursor: pointer;
    filter: drop-shadow(1.5px 1.5px 1.5px #bbbaba)
}
.icon:hover {
    animation-name: hovering;
    animation-duration: .35s;
    animation-timing-function: ease-in-out;
}

@keyframes hovering {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); filter: drop-shadow(5px 5px 5px #bbbaba) }
    100% { transform: scale(1); }
}